import logo from './dvaraLogo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className = "headerContainer">
            <div className = "dvaraLogoImgWrapper">
              <img className = ""src = {logo} alt = "DvaraLogo"/>
            </div>
            <div className = "headerCustomTitleWrapper">
              <div className = "headerCustomTitle">DVARA E-DAIRY PRIVACY POLICY</div>
            </div>
      </div>
      <p style={{textAlign:'left'}}>All visitors who use or access the mobile application and/or the website operated by Dvara E-Dairy Solutions Private Limited are covered under this Privacy Policy (“Policy”). In addition to this Policy, you will also be governed by the Terms and Conditions ("Terms") accessible on the mobile application [insert name of the application] and/or the website [insert the link] (the mobile application and the website collectively referred to as the “Platform”). Use of the Platform and/or our services is available only to Persons who can enter into legally binding contracts under the Indian Contract Act, 1872 and to Persons resident in India. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents and persons of unsound mind are not eligible to use the Platform or the services.</p>
      <p style={{textAlign:'left'}}>Dvara E-Dairy Solutions Private Limited (hereinafter shall be referred to as “Company”, “E-Dairy Solutions”, “We”, “Us”, “Our” and terms of similar meaning) owns and operates the Platform and provides services vide the Platform</p>
      <p style={{textAlign:'left'}}>By accessing the Platform or otherwise using the Platform, you (hereinafter referred to as “You”, “Your”, “User” as applicable) expressly consent to Our collection, storage, use and disclosure of the Information (as defined hereunder) in accordance with the terms of this Policy.</p>
      <p style={{textAlign:'left'}}><b>1.	Fundamentals of the Policy</b></p>
      <p style={{textAlign:'left'}}>We have designed this Policy keeping in mind certain fundamental principles to protect and preserve the integrity of the Information shared by the User including:</p>
      <p style={{textAlign:'left'}}>1.1.	Managerial, organisational, business practices and technical systems in the Company are designed in a manner to anticipate, identify and avoid harm to the User;</p>
      <p style={{textAlign:'left'}}>1.2.	Technology used in the processing of personal data is in accordance with commercially accepted or certified standards;</p>
      <p style={{textAlign:'left'}}>1.3.	Legitimate interests of businesses including any innovation is achieved without compromising privacy interests;</p>
      <p style={{textAlign:'left'}}>1.4.	Privacy is protected throughout processing of Information, from the point of collection to deletion of personal data.</p>
      <p style={{textAlign:'left'}}> <b>2.	What do we Collect?</b></p>
      <p style={{textAlign:'left'}}>2.1.	To enable Us to provide services to You or for You to access/utilise the Platform, You may be required to provide certain personal, non-personal and other information, as indicated below (such information and correspondences hereinafter collectively referred to as “Data/Information”):</p>
      <p style={{textAlign:'left'}}>a)	We may require you to provide us with certain information that personally identifies You ("Personal Information"). Personal Information includes the following categories of information: (i) Contact Data (such as your e-mail address, phone number and Account password); and (ii) Demographic Data (such as your gender, your date of birth etc), but does not include information freely available and/or accessible in the public domain.</p>
      <p style={{textAlign:'left'}}>b)	We may receive information about Your location and Your mobile device, including a unique identifier number for Your device. We may use this information to provide You with suggestions in respect of services if required.</p>
      <p style={{textAlign:'left'}}>c)	Sometimes, You may not be actively submitting such information. Unidentifiable information may be collected using various technologies, such as cookies, tags, and web beacons. Your browser automatically transmits to Us some of this unidentifiable information. These kinds of information allows Us to provide better service, customize sites based on consumer preferences, compile statistics, analyze trends, and otherwise administer and improve Our Platform. This type of information does not identify You personally.</p>
      <p style={{textAlign:'left'}}>d)	When You send emails or other communications to Us, we may retain those communications in order for Us to process Your inquiries, respond to Your requests and improve our services.</p>
      <p style={{textAlign:'left'}}>2.2.	You can choose to decline to submit any Information, but in such a case, We may not be able to provide You access to the Platform or certain aspects of the Platform or the services.</p>
      <p style={{textAlign:'left'}}><b>3.	What do we do with the Information?</b></p>
      <p style={{textAlign:'left'}}>3.1.	We will always process the Information only in accordance with this Privacy Policy and as permitted by applicable law. We may process the Information You provide in the following manner:</p>
      <p style={{textAlign:'left'}}>a)	To provide services to you as set forth in our Platform;</p>
      <p style={{textAlign:'left'}}>b)	To allow our professionals, business partners to assist us with providing services to You, to connect with You to provide their services/products You may specifically consent to be contacted for;</p>
      <p style={{textAlign:'left'}}>c)	To contact You and to customise Our communication with You and the marketing material we share with You;</p>
      <p style={{textAlign:'left'}}>d)	To analyse and suggest any other related product/service that shall be suitable for you;</p>
      <p style={{textAlign:'left'}}>e)	To avoid fraud and other prohibited or illegal activities;</p>
      <p style={{textAlign:'left'}}>f)	To respond to authorized information requests from governmental authorities or when otherwise required by law;</p>
      <p style={{textAlign:'left'}}>g)	To enforce the legal terms (including without limitation Our policies and Terms) that govern your use of Our services, and/or for the purposes for which you provided the Information;</p>
      <p style={{textAlign:'left'}}>h)	To protect the security or integrity of the Platform, Our business, services and other users; and/or</p>
      <p style={{textAlign:'left'}}>i)	To publish any testimonials or reviews that You may have provided on the Platform.</p>
      <p style={{textAlign:'left'}}>3.2.	We do not sell, trade, or otherwise transfer to third parties Your Information. This does not include trusted third parties who assist Us in operating Our Platform, conducting Our business, or servicing You. When we provide Your Personal Information to individuals and entities which assist us with Our business activities, it is Our practice to require those individuals and entities to keep such Information confidential and to use Your Personal Information only to perform services.</p>
      <p style={{textAlign:'left'}}>3.3.	Additionally, in the event of an audit, reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of Our business, assets or stock (including in connection with any bankruptcy or similar proceedings), We may share/transfer/assign the Information we have collected to the relevant third party.</p>
      <p style={{textAlign:'left'}}><b>4.	How long do we hold Information?</b></p>
      <p style={{textAlign:'left'}}>We retain Personal Information only for as long as we have Your consent. We retain Your Personal Information for as long as We have an ongoing relationship with You and You continue to access/use the Platform. However, we retain copies of Your Information if there is a legal obligation to which We are subject or keeping in view the legitimate interests of the Company (litigation or statutory requirements).</p>
      <p style={{textAlign:'left'}}><b>5.	How safe is your Information?</b></p>
      <p style={{textAlign:'left'}}>We use reasonable security measures to protect Information from unauthorized access, maintain data accuracy and help ensure the appropriate use/disclosure of Information. We host the Platform in a secure server environment that uses firewalls and other advanced technology to prevent interference or access from outside intruders.</p>
      <p style={{textAlign:'left'}}><b>6.	Third-Party Links</b></p>
      <p style={{textAlign:'left'}}>Our Website may contain links to or integrations with other services including social media services and platforms whose information practices may be different than ours. You should consult these other services' privacy notices as we have no control over information that is submitted to, or collected by, these third parties.</p>
      <p style={{textAlign:'left'}}><b>7.	Miscellaneous</b></p>
      <p style={{textAlign:'left'}}>7.1.	As we update, improve and expand the Platform, We may update this Privacy Policy. We will notify you of the changes and any access/use of the Platform subsequent to such notification will imply you have agreed to the modified terms of the Policy.</p>
      <p style={{textAlign:'left'}}>7.2.	We will not be responsible for the accuracy or filtration of any of the content posted on Our Platform by any user, however, We will be entitled to remove any content posted by any user on Our Platform which We, at Our sole discretion, consider offensive, discriminatory, racist, defamatory, inappropriate in nature or unlawful.</p>
      <p style={{textAlign:'left'}}>We would be happy to address any questions regarding this Privacy Policy. Please reach to us at dvaraedairy2020@gmail.com.</p>
      <strong style={{padding:'2%'}}>@ Copyrights 2021, Dvara Edairy-Solutions. All Rights Reserved.</strong>
    </div>
  );
}

export default App;
